html {
  font-family: "ff-enzo-web",tahoma,sans-serif; }

div#body-section {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-family: "ff-enzo-web",tahoma,sans-serif; }

#body-section {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-grow: 1; }
  #body-section > div {
    flex-grow: 1;
    padding: 24px;
    overflow-y: auto; }

.contact-map {
  flex-grow: 1;
  margin: 50px; }
  .contact-map iframe {
    width: 800px;
    height: 600px; }

.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px; }

.centered-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px; }

#woofles-logo {
  max-width: 150px;
  max-height: 150px;
  margin-bottom: 16px;
  margin-top: 32px; }

#forgot-password-link {
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  padding: 5px; }
  #forgot-password-link:focus {
    outline: none; }

.link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer; }
  .link:hover {
    color: #0056b3;
    text-decoration: underline; }

.alert dl, ol, ul {
  margin: 0; }

.alert {
  font-size: 13px;
  position: relative;
  padding: 1px;
  margin: 0;
  margin-left: 18px; }

.alert-danger {
  font-style: italic;
  color: #ff0000;
  background-color: unset;
  border: unset; }

.bottom-padding-12 {
  padding-bottom: 12px; }

.bottom-padding-32 {
  padding-bottom: 32px; }

.validate-email.message {
  font-weight: bold; }

.validate-email.token {
  font-style: italic; }

.validate-email.error {
  font-weight: bold;
  color: #ff0000; }

.full-width-flex {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .full-width-flex.email-config {
    flex-direction: column;
    align-items: normal; }
    .full-width-flex.email-config .item {
      align-items: center; }

input {
  display: inline-block;
  font-weight: 400;
  padding: 5px 5px;
  border: solid 1px #c98989;
  border-radius: 5px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  margin: 4px; }
  input:focus {
    outline: none; }

textarea {
  display: inline-block;
  font-weight: 400;
  padding: 5px 5px;
  border: solid 1px #c98989;
  border-radius: 5px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  margin: 4px; }
  textarea:focus {
    outline: none; }

.buttons {
  display: flex;
  flex-direction: row;
  margin: 4px;
  margin-top: 24px;
  flex-wrap: wrap;
  justify-content: center; }

.grow {
  flex-grow: 1; }

.error {
  color: red;
  fill: red; }

.flex-row {
  display: flex;
  flex-direction: row; }

.flex-col {
  display: flex;
  flex-direction: column; }

.green-text {
  color: lightseagreen;
  fill: lightseagreen; }

.darkgreen-text {
  color: green;
  fill: green; }

.red-text {
  color: red;
  fill: red; }

.darkred-text {
  color: darkred;
  fill: darkred; }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0; }

.pad-8 {
  padding: 0px 8px; }

.h5, h5 {
  font-size: 1.25rem; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2; }

table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  padding: 4px;
  border-spacing: 0;
  margin-bottom: 1rem; }
  table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    text-align: left;
    border-bottom: 1px solid #000; }
  table tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit; }
  table.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05); }

bp-sidebar {
  flex-direction: column;
  display: flex;
  padding-top: 24px;
  width: 222px;
  flex-grow: 0;
  border-right: 1px solid #bfbfbf;
  flex-shrink: 0;
  background-color: #fff;
  align-items: flex-end;
  overflow-y: auto; }

bp-sidebar-link > div {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  margin: 4px 0;
  padding: 1px;
  padding-right: 8px;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: background-color 0.15s ease-out, box-shadow 0.15s ease-out;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
  width: 200px;
  height: 50px;
  color: black; }
  bp-sidebar-link > div:hover {
    background-color: #f0f0f0; }

bp-sidebar-link .icon {
  width: 24px;
  height: 24px;
  margin-left: 6px;
  margin-right: 6px; }

bp-sidebar-link .label {
  white-space: nowrap; }

bp-sidebar-link .description {
  font-size: 12px;
  color: #999;
  white-space: nowrap; }

bp-sidebar-link .text {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-right: 8px; }

#profile-index .title {
  display: flex;
  flex-direction: row; }

#profile-index .item {
  display: flex;
  flex-direction: row;
  padding: 8px; }
  #profile-index .item .label {
    font-size: 0.75em;
    opacity: 1;
    min-width: 150px; }
  #profile-index .item .value {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 0.85em;
    opacity: 0.8; }

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 26px; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

input:checked + .slider {
  background-color: #2196F3; }

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3; }

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

@media (min-width: 976px) and (max-width: 1500px) {
  body #header #header-logo {
    margin: 4px 0px;
    width: 220px; }
    body #header #header-logo img {
      max-width: 200px; }
  body wp-header-link {
    padding-right: 20px;
    margin: 0; }
    body wp-header-link .link-icon svg {
      width: 18px;
      height: 18px; }
    body wp-header-link .link-label {
      font-size: 11px;
      white-space: pre-wrap; } }

.contact-container {
  border: 1px #2d2d2d solid;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  /* ///// inputs /////*/ }
  .contact-container h1 {
    font-family: 'Poppins', sans-serif, 'arial';
    font-weight: 600;
    font-size: 72px;
    color: #2d2d2d;
    text-align: center; }
  .contact-container h4 {
    font-family: 'Roboto', sans-serif, 'arial';
    font-weight: 400;
    font-size: 20px;
    color: #9b9b9b;
    line-height: 1.5; }
    .contact-container h4.success {
      color: forestgreen; }
    .contact-container h4.failed {
      color: darkred; }
  .contact-container input:focus ~ label, .contact-container textarea:focus ~ label, .contact-container input:valid ~ label, .contact-container textarea:valid ~ label {
    font-size: 0.75em;
    color: #999;
    top: -5px;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease; }
    .contact-container input:focus ~ label.required, .contact-container textarea:focus ~ label.required, .contact-container input:valid ~ label.required, .contact-container textarea:valid ~ label.required {
      color: #ff0000; }
  .contact-container .styled-input {
    float: left;
    width: 293px;
    margin: 1rem 0;
    position: relative;
    border-radius: 4px; }
  @media only screen and (max-width: 768px) {
    .contact-container .styled-input {
      width: 100%; } }
  .contact-container .styled-input label {
    color: #999;
    padding: 1.3rem 30px 1rem 30px;
    position: absolute;
    top: 10px;
    left: 0;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    pointer-events: none; }
    .contact-container .styled-input label .required {
      color: #a50a0a; }
  .contact-container .styled-input.wide {
    width: 650px;
    max-width: 100%; }
  .contact-container input,
  .contact-container textarea {
    padding: 30px;
    border: 0;
    width: 100%;
    font-size: 1rem;
    background-color: #2d2d2d;
    color: white;
    border-radius: 4px; }
  .contact-container input:focus,
  .contact-container textarea:focus {
    outline: 0; }
  .contact-container input:focus ~ span,
  .contact-container textarea:focus ~ span {
    width: 100%;
    -webkit-transition: all 0.075s ease;
    transition: all 0.075s ease; }
    .contact-container input:focus ~ span .required,
    .contact-container textarea:focus ~ span .required {
      color: #a50a0a; }
  .contact-container textarea {
    width: 100%;
    min-height: 15em; }
  .contact-container .input-container {
    width: 650px;
    max-width: 100%;
    margin: 20px auto 25px auto; }
  .contact-container .submit-btn {
    float: right;
    padding: 7px 35px;
    border-radius: 60px;
    display: inline-block;
    background-color: #4b8cfb;
    color: white;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06), 0 2px 10px 0 rgba(0, 0, 0, 0.07);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease; }
  .contact-container .submit-btn:hover {
    transform: translateY(1px);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.09); }
  @media (max-width: 768px) {
    .contact-container .submit-btn {
      width: 100%;
      float: none;
      text-align: center; } }
  .contact-container input[type=checkbox] + label {
    color: #ccc;
    font-style: italic; }
  .contact-container input[type=checkbox]:checked + label {
    color: #f00;
    font-style: normal; }

#header {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #ffffff;
  padding: 10px;
  height: 54px;
  border-bottom: 1px solid #22556d; }
  #header #header-logo {
    margin: 4px 40px;
    width: 160px;
    align-self: center;
    background-image: url("https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/WooflesLogo.png"); }
  #header #header-cart {
    margin: 4px 40px;
    width: 160px;
    height: 64px;
    align-self: center; }
  #header #header-menu {
    display: flex;
    flex-direction: column;
    height: 32px;
    align-self: center;
    align-items: center;
    flex-grow: 1; }

wp-header-link {
  display: inline-flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
  margin: 4px;
  padding-right: 32px;
  transition: color 0.15s ease-out, box-shadow 0.15s ease-out;
  color: black;
  font-weight: bold;
  cursor: pointer; }
  wp-header-link.active {
    color: steelblue; }
    wp-header-link.active svg {
      fill: steelblue; }
  wp-header-link.hasItems {
    color: #4CAF50; }
    wp-header-link.hasItems svg {
      fill: #4CAF50; }
  wp-header-link .link-icon svg {
    width: 24px;
    height: 24px; }
  wp-header-link .link-label {
    font-size: 13px;
    white-space: nowrap; }

wp-header-link .mobile {
  display: inline-flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
  transition: color 0.15s ease-out, box-shadow 0.15s ease-out;
  color: black;
  font-weight: bold;
  cursor: pointer; }
  wp-header-link .mobile.active {
    color: steelblue; }
    wp-header-link .mobile.active svg {
      fill: steelblue; }
  wp-header-link .mobile .link-icon svg {
    width: 24px;
    height: 24px; }
  wp-header-link .mobile .link-label {
    font-size: 13px;
    white-space: nowrap; }

#mobile-header {
  display: none;
  flex-shrink: 0;
  align-items: center;
  background-color: #2665cc60;
  color: white;
  font-size: 16px;
  padding: 0px 16px;
  height: 64px;
  box-shadow: 0 0 16px #00000050; }
  #mobile-header .mobile-logo {
    width: 190px;
    background-image: url("https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/WooflesLogo.png"); }
  #mobile-header #mobile-menu-button {
    width: 32px;
    height: 32px;
    padding: 4px;
    fill: white; }

#sub-header {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #ececec;
  padding: 10px;
  height: 40px;
  border-radius: 10px;
  margin-top: 12px;
  margin-bottom: 12px; }
  #sub-header #sub-header-menu {
    display: flex;
    height: 32px;
    align-self: center;
    align-items: center;
    flex-grow: 1; }
  #sub-header wp-header-link {
    display: inline-flex;
    align-items: center;
    text-align: left;
    font-size: 16px;
    margin: 4px;
    transition: color 0.15s ease-out, box-shadow 0.15s ease-out;
    color: black;
    font-weight: bold;
    cursor: pointer;
    padding: 10px; }
    #sub-header wp-header-link:hover {
      background-color: #c3c3c3;
      border-radius: 10px;
      margin: 4px; }
    #sub-header wp-header-link.active {
      color: steelblue; }
      #sub-header wp-header-link.active svg {
        fill: steelblue; }
    #sub-header wp-header-link .link-icon svg {
      width: 24px;
      height: 24px; }
    #sub-header wp-header-link .link-label {
      font-size: 13px;
      white-space: nowrap; }
  #sub-header wp-sub-header-link {
    align-items: center;
    text-align: left;
    font-size: 16px;
    margin: 4px;
    transition: color 0.15s ease-out, box-shadow 0.15s ease-out;
    color: black;
    font-weight: bold;
    cursor: pointer;
    padding: 10px; }
    #sub-header wp-sub-header-link:hover {
      border-radius: 10px;
      margin: 4px; }
    #sub-header wp-sub-header-link .link-icon svg {
      width: 24px;
      height: 24px; }
    #sub-header wp-sub-header-link .link-label {
      font-size: 13px;
      white-space: nowrap; }

.sub-header-item {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .sub-header-item svg {
    padding-right: 8px;
    fill: lightsteelblue; }
  .sub-header-item:hover svg {
    fill: blue; }

.mobile #header {
  display: none; }

.mobile #mobile-header {
  display: flex; }

.grow {
  flex-grow: 1; }

#mobile-lightbox {
  position: fixed;
  top: 0px;
  left: -100%;
  bottom: 0px;
  width: 100%;
  background-color: white;
  transition: left 50ms ease-out;
  z-index: 2000; }

#mobile-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 90%;
  background-color: white;
  box-shadow: 0px 0px 20px #333;
  opacity: 0;
  transition: opacity 50ms ease-out 0s; }

#mobile-menu-header {
  display: flex;
  align-items: center;
  background-color: #2665cc60;
  color: black;
  font-size: 16px;
  padding: 0px 16px;
  height: 64px;
  flex-shrink: 0; }

#mobile-menu-logo-container {
  text-align: center;
  flex-grow: 1; }

#mobile-navigation {
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  flex-shrink: 0; }

.mobile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  font-size: 12px;
  text-align: center;
  flex-grow: 1;
  margin-top: 8px;
  opacity: 0.8;
  height: 56px; }
  .mobile-nav:hover {
    opacity: 1; }
  .mobile-nav svg {
    width: 32px;
    height: 32px; }

#mobile-nav-scroll {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden; }

#mobile-lightbox.open #mobile-menu {
  opacity: 1;
  transition: opacity 170ms ease-out 0s; }

.mobile #mobile-lightbox.open {
  left: 0px;
  background: rgba(0, 0, 0, 0.1);
  transition: left 100ms ease-out 0s, background .5s ease-out 50ms; }

.mobile #mobile-menu {
  opacity: 1; }

#mobile-menu-close {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 4px;
  fill: white; }

@keyframes menu-open {
  0% {
    overflow: hidden; }
  99.99% {
    overflow: hidden; }
  100% {
    overflow: hidden auto; } }

#mobile-lightbox {
  position: fixed;
  top: 0px;
  left: -100%;
  bottom: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: left 50ms ease-out;
  z-index: 2000; }

#mobile-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 90%;
  background-color: white;
  box-shadow: 0px 0px 20px #333;
  opacity: 0;
  transition: opacity 50ms ease-out 0s; }

#mobile-menu-header {
  display: flex;
  align-items: center;
  background-color: #2665cc60;
  color: white;
  font-size: 16px;
  padding: 0px 16px;
  height: 64px;
  flex-shrink: 0; }

#mobile-menu-logo-container {
  text-align: center;
  flex-grow: 1; }

#mobile-header-logo svg {
  vertical-align: middle;
  width: 160px;
  height: 64px; }

#mobile-breadcrumbs {
  display: flex;
  align-items: center;
  background-color: #eee;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-right: 32px;
  height: 48px;
  flex-shrink: 0; }

.mobile-breadcrumbs-item {
  padding: 12px; }
  .mobile-breadcrumbs-item:last-child {
    padding-right: 48px; }

#mobile-navigation {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  flex-shrink: 0; }

.mobile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  font-size: 12px;
  text-align: center;
  flex-grow: 1;
  margin-top: 8px;
  opacity: 0.8;
  height: 56px; }
  .mobile-nav:hover {
    opacity: 1; }
  .mobile-nav svg {
    width: 32px;
    height: 32px; }

#mobile-nav-scroll {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden; }

#mobile-footer {
  background-color: #eeeeee;
  font-size: 12px;
  text-align: center; }
  #mobile-footer p {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 6px; }

#mobile-lightbox.open #mobile-menu {
  opacity: 1;
  transition: opacity 170ms ease-out 0s; }

.mobile #mobile-lightbox.open {
  left: 0px;
  background: rgba(0, 0, 0, 0.1);
  transition: left 100ms ease-out 0s, background .5s ease-out 50ms; }

.mobile #mobile-menu {
  opacity: 1; }

.mobile #sub-header {
  display: none; }

.mobile .contact-container h1 {
  font-size: 36px; }

.mobile .contact-container h4 {
  font-size: 16px; }

.mobile .contact-container input[type="text"], .mobile .contact-container textarea {
  width: 145px; }

.mobile .contact-container .btn-lrg.submit-btn {
  width: 140px; }

.mobile bp-sidebar {
  display: none; }

.mobile #profile-index.email-config {
  align-items: start; }

.mobile #profile-index .item {
  flex-direction: column; }

.mobile #contact-details .contact-map {
  margin: 0; }

.mobile #contact-details iframe {
  width: 300px;
  height: 200px; }

.mobile #body-section > div {
  padding: 24px 0; }

.mobile #body-section th {
  font-size: 14px; }

.mobile #body-section td {
  padding: 4px;
  font-size: 12px; }

#mobile-menu-close {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 4px;
  fill: white; }

bp-footer {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 1;
  background-color: #f2f2f2;
  border-top: 1px solid #bfbfbf;
  padding: 5px;
  width: 100%; }
  bp-footer a {
    font-size: 14px;
    color: black; }

.mobile #shop .products {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fbfbfb;
  padding: 12px;
  margin-bottom: 12px; }
  .mobile #shop .products .product-name {
    font-size: 16px; }

#shop {
  display: flex;
  flex-direction: column;
  /* Rounded sliders */ }
  #shop .flex-grow {
    flex-grow: 1; }
  #shop .products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fbfbfb;
    padding: 12px;
    margin-bottom: 12px; }
    #shop .products img {
      max-width: 120px;
      max-height: 100px;
      padding: 10px; }
    #shop .products .product-icons {
      display: flex; }
    #shop .products div > svg:hover {
      box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.589);
      transform: scale(1.2);
      transition: all 0.2s ease-in-out; }
    #shop .products div > svg {
      margin: 4px 10px;
      width: 35px;
      height: 35px;
      border-radius: 50%; }
    #shop .products button.add-to-cart {
      background-color: #4CAF50;
      /* Green */
      border: none;
      color: white;
      width: 145px;
      cursor: pointer;
      border-radius: 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px; }
    #shop .products button.update-cart {
      background-color: #4CAF50;
      /* Green */
      border: none;
      color: white;
      width: 78px;
      padding: 16px 8px;
      cursor: pointer;
      border-radius: 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px; }
    #shop .products button.remove {
      background-color: darkred;
      border: none;
      color: white;
      width: 36px;
      padding: 16px 8px;
      cursor: pointer;
      border-radius: 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px; }
    #shop .products button.increment {
      background-color: #4CAF50;
      /* Green */
      border: none;
      color: white;
      width: 36px;
      padding: 16px 8px;
      cursor: pointer;
      border-radius: 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px; }
    #shop .products button.decrement {
      background-color: #CECECE;
      /* Green */
      border: none;
      color: black;
      width: 36px;
      padding: 16px 8px;
      cursor: pointer;
      border-radius: 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px; }
    #shop .products .add-to-cart {
      display: flex;
      align-items: center;
      height: 48px;
      min-width: 150px; }
      #shop .products .add-to-cart .quantity {
        width: 30px;
        padding: 4px;
        height: 24px; }
      #shop .products .add-to-cart div > svg:hover {
        box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.589);
        transform: scale(1.2);
        transition: all 0.2s ease-in-out; }
      #shop .products .add-to-cart div > svg {
        padding: 2px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        fill: #33a1ff;
        stroke: #33a1ff; }
    #shop .products .price {
      padding-top: 10px;
      font-weight: 700; }
    #shop .products .product-description {
      padding: 8px 0;
      color: #117bd5;
      display: flex;
      font-size: 16px;
      align-items: center;
      white-space: pre-line;
      margin: 0 0 12px 0;
      max-width: 750px; }
    #shop .products .product-packaging {
      display: flex;
      font-size: 14px;
      align-items: center; }
      #shop .products .product-packaging svg {
        height: 24px; }
    #shop .products svg {
      height: 24px; }
    #shop .products .product-name {
      font-weight: 700;
      font-size: 18px;
      color: #33a1ff;
      margin-top: 6px;
      cursor: default; }
  #shop .announcement {
    margin-top: 20px; }
  #shop button.checkout {
    background-color: #4CAF50;
    /* Green */
    border: none;
    color: white;
    width: 200px;
    opacity: 0.8;
    padding: 8px 8px;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 8px; }
    #shop button.checkout:hover {
      font-weight: 600;
      opacity: 1; }
  #shop button.clear-cart {
    background-color: #ffffff;
    /* Red */
    border: 1px solid #fd4040;
    color: #fd4040;
    width: 200px;
    opacity: 0.7;
    padding: 8px 8px;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 8px; }
    #shop button.clear-cart:hover {
      opacity: 1;
      background-color: #cecece;
      font-weight: 600; }
  #shop .radio-toolbar {
    margin: 10px; }
  #shop .radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0; }
  #shop .radio-toolbar label {
    display: inline-block;
    background-color: #ddd;
    padding: 10px 20px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    border: 2px solid #444;
    border-radius: 4px; }
  #shop .radio-toolbar label:hover {
    background-color: #dfd; }
  #shop .radio-toolbar input[type="radio"]:focus + label {
    border: 2px dashed #444; }
  #shop .radio-toolbar input[type="radio"]:checked + label {
    background-color: #bfb;
    border-color: #4c4; }
  #shop .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px; }
  #shop .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  #shop .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s; }
  #shop .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s; }
  #shop input:checked + .slider {
    background-color: #4c4; }
  #shop input:focus + .slider {
    box-shadow: 0 0 1px #4c4; }
  #shop input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px); }
  #shop .slider.round {
    border-radius: 34px; }
  #shop .slider.round:before {
    border-radius: 50%; }

/* HIDE RADIO */
[type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0; }

/* IMAGE STYLES */
[type=radio] + img {
  cursor: pointer; }

/* CHECKED STYLES */
[type=radio]:checked + img {
  outline: 2px solid #f00; }

.oldPrice {
  text-decoration: line-through;
  color: red; }

.shop-display {
  /* width: 96vw;
  height: 98vh;*/
  width: 3840px;
  height: 2160px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 30px; }
  .shop-display .highlight-row {
    background-color: #83be26; }
  .shop-display .pricing {
    background-color: #f4fcff; }
    .shop-display .pricing h3 {
      margin-block-start: 0;
      text-align: center;
      border-bottom: 3px solid #83be26;
      padding-bottom: 10px; }
    .shop-display .pricing .icons {
      display: flex;
      flex-direction: row; }
    .shop-display .pricing svg {
      height: 32px;
      width: 32px;
      margin-right: 10px; }
    .shop-display .pricing .container {
      width: 3840px;
      height: 2160px;
      background-image: url("https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/top-bg-alt2.png");
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: top center;
      font-size: 4em;
      overflow: hidden; }
    .shop-display .pricing .header-row {
      display: inline-flex;
      flex-direction: row;
      width: 100%;
      align-items: center; }
    .shop-display .pricing .logo-box {
      height: 580px;
      width: 400px;
      flex-grow: 1; }
    .shop-display .pricing .center-box {
      flex-grow: 1;
      padding: 50px; }
    .shop-display .pricing .right-box {
      height: 500px;
      width: 1300px;
      flex-grow: 0; }
    .shop-display .pricing .inside-right-box {
      background-color: white;
      width: 90%;
      height: 80%;
      border: 10px solid #83be26;
      border-radius: 50px;
      transform: skewX(-10deg);
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; }
    .shop-display .pricing .unskew {
      transform: skewX(10deg);
      display: flex; }
    .shop-display .pricing .unskew-image {
      width: 450px;
      height: 450px; }
      .shop-display .pricing .unskew-image img {
        width: 400px;
        margin: 0 0 0 -85px; }
    .shop-display .pricing .unskew-text {
      display: flex;
      flex-direction: column;
      justify-items: center;
      font-weight: bold;
      font-size: 1.0em; }
    .shop-display .pricing .description {
      font-size: 0.7em;
      font-weight: 300; }
    .shop-display .pricing .pricing {
      font-size: 0.8em;
      font-weight: 600;
      color: red;
      margin-bottom: -40px; }
    .shop-display .pricing .logo-image {
      width: 1700px;
      padding-top: 100px; }
    .shop-display .pricing .dog-image {
      height: 562px;
      padding-left: 20px; }
    .shop-display .pricing .content {
      padding-right: 50px;
      padding-left: 50px;
      padding-bottom: 50px;
      padding-top: 0px;
      display: flex;
      height: 73%; }
    .shop-display .pricing .section {
      margin: 10px;
      flex-grow: 1;
      width: 30%;
      border: #83be26 10px solid;
      border-radius: 20px;
      padding: 20px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; }
    .shop-display .pricing .section table {
      font-size: 0.6em;
      padding: 10px;
      border-collapse: collapse; }
      .shop-display .pricing .section table tr {
        border-bottom: 3px dotted #83be26; }

.grow {
  flex-grow: 1; }

.window-display {
  /* width: 96vw;
  height: 98vh;*/
  width: 3840px;
  height: 2160px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 30px; }
  .window-display .highlight-row {
    background-color: #83be26; }
  .window-display .pricing {
    background-color: #f4fcff; }
    .window-display .pricing h3 {
      margin-block-start: 0;
      text-align: center;
      border-bottom: 3px solid #83be26;
      padding-bottom: 10px; }
    .window-display .pricing h1 {
      font-size: 5em;
      text-align: center;
      vertical-align: middle;
      font-weight: bold; }
    .window-display .pricing h2 {
      font-size: 3em;
      text-align: center;
      vertical-align: middle;
      font-weight: bold; }
    .window-display .pricing .icons {
      display: flex;
      flex-direction: row; }
    .window-display .pricing svg {
      height: 32px;
      width: 32px;
      margin-right: 10px; }
    .window-display .pricing .container {
      width: 3840px;
      height: 2160px;
      background-image: url("https://woofles-images.s3.ap-southeast-2.amazonaws.com/shop-displays/top-bg-alt4.png");
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: top center;
      font-size: 4em;
      overflow: hidden; }
    .window-display .pricing .header-row {
      display: inline-flex;
      flex-direction: row;
      width: 100%;
      align-items: center; }
    .window-display .pricing .logo-box {
      width: 00px;
      flex-grow: 1; }
    .window-display .pricing .center-box {
      flex-grow: 1;
      margin-top: -1600px; }
    .window-display .pricing .description {
      font-size: 0.7em;
      font-weight: 300; }
    .window-display .pricing .pricing {
      font-size: 0.8em;
      font-weight: 600;
      color: red;
      margin-bottom: -40px; }
    .window-display .pricing .logo-image {
      width: 2150px;
      padding-top: 200px;
      padding-left: 1100px; }
    .window-display .pricing .dog-image {
      height: 2160px;
      /*padding-left: 20px;*/
      margin-top: 30px;
      margin-left: -130px; }
    .window-display .pricing .content {
      padding-right: 50px;
      padding-left: 50px;
      padding-bottom: 50px;
      padding-top: 0px;
      display: flex;
      height: 69%;
      background-color: transparent;
      margin-top: -1540px;
      /*margin-left: 1200px;*/ }
    .window-display .pricing .section {
      margin: 20px;
      flex-grow: 1; }
    .window-display .pricing .section table {
      font-size: 0.6em;
      padding: 10px;
      border-collapse: collapse; }
      .window-display .pricing .section table tr {
        border-bottom: 3px dotted #83be26; }

.grow {
  flex-grow: 1; }

button {
  display: inline-flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
  margin: 4px;
  padding-right: 8px;
  cursor: pointer;
  transition: background-color 0.15s ease-out, box-shadow 0.15s ease-out;
  background-color: #f9f9f9;
  border-radius: 7px;
  border: solid 1px #c98989;
  width: 180px;
  height: 50px; }
  button:hover {
    background-color: #ececec; }
  button:focus {
    outline: none; }
  button.disable {
    background-color: initial;
    cursor: default;
    opacity: 0.4; }
  button .invisible {
    display: none; }
  button .button-icon svg {
    width: 24px;
    height: 24px;
    margin-left: 2px;
    margin-right: 6px; }
  button .button-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-right: 8px; }
  button .button-title {
    white-space: nowrap;
    color: black; }
  button .button-description {
    font-size: 12px;
    color: #999;
    white-space: nowrap; }

#bp-dialog-overlay, wp-notification-dialog, bp-dialog {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000082;
  position: absolute;
  z-index: 10;
  /*}

bp-confirm-dialog, bp-dialog {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000082;
  position: absolute;
  z-index: 10;
*/ }
  #bp-dialog-overlay #wp-notification-dialog, wp-notification-dialog #wp-notification-dialog, bp-dialog #wp-notification-dialog {
    display: flex;
    flex-direction: column;
    max-width: 640px;
    min-width: 320px;
    margin: auto;
    height: 100%; }
    #bp-dialog-overlay #wp-notification-dialog .header, wp-notification-dialog #wp-notification-dialog .header, bp-dialog #wp-notification-dialog .header {
      flex-grow: 3; }
    #bp-dialog-overlay #wp-notification-dialog .footer, wp-notification-dialog #wp-notification-dialog .footer, bp-dialog #wp-notification-dialog .footer {
      flex-grow: 5; }
    #bp-dialog-overlay #wp-notification-dialog .dialog-body, wp-notification-dialog #wp-notification-dialog .dialog-body, bp-dialog #wp-notification-dialog .dialog-body {
      background-color: white;
      overflow: hidden; }
      #bp-dialog-overlay #wp-notification-dialog .dialog-body .title, wp-notification-dialog #wp-notification-dialog .dialog-body .title, bp-dialog #wp-notification-dialog .dialog-body .title {
        color: white;
        fill: white;
        background-color: steelblue;
        font-weight: 700;
        min-width: 180px;
        font-size: 16px;
        padding: 8px; }
      #bp-dialog-overlay #wp-notification-dialog .dialog-body .content, wp-notification-dialog #wp-notification-dialog .dialog-body .content, bp-dialog #wp-notification-dialog .dialog-body .content {
        padding: 8px; }
        #bp-dialog-overlay #wp-notification-dialog .dialog-body .content .fields, wp-notification-dialog #wp-notification-dialog .dialog-body .content .fields, bp-dialog #wp-notification-dialog .dialog-body .content .fields {
          margin-top: 8px; }
          #bp-dialog-overlay #wp-notification-dialog .dialog-body .content .fields .field, wp-notification-dialog #wp-notification-dialog .dialog-body .content .fields .field, bp-dialog #wp-notification-dialog .dialog-body .content .fields .field {
            margin-top: 8px;
            margin-bottom: 16px; }
            #bp-dialog-overlay #wp-notification-dialog .dialog-body .content .fields .field input, #bp-dialog-overlay #wp-notification-dialog .dialog-body .content .fields .field select, wp-notification-dialog #wp-notification-dialog .dialog-body .content .fields .field input, wp-notification-dialog #wp-notification-dialog .dialog-body .content .fields .field select, bp-dialog #wp-notification-dialog .dialog-body .content .fields .field input, bp-dialog #wp-notification-dialog .dialog-body .content .fields .field select {
              width: 98%;
              padding: 4px;
              border-radius: 4px;
              border: 1px solid #a8c4ff;
              margin-top: 4px; }
            #bp-dialog-overlay #wp-notification-dialog .dialog-body .content .fields .field select, wp-notification-dialog #wp-notification-dialog .dialog-body .content .fields .field select, bp-dialog #wp-notification-dialog .dialog-body .content .fields .field select {
              padding: 6px; }
            #bp-dialog-overlay #wp-notification-dialog .dialog-body .content .fields .field input[type='checkbox'], wp-notification-dialog #wp-notification-dialog .dialog-body .content .fields .field input[type='checkbox'], bp-dialog #wp-notification-dialog .dialog-body .content .fields .field input[type='checkbox'] {
              width: 60px; }
            #bp-dialog-overlay #wp-notification-dialog .dialog-body .content .fields .field span.checkbox, wp-notification-dialog #wp-notification-dialog .dialog-body .content .fields .field span.checkbox, bp-dialog #wp-notification-dialog .dialog-body .content .fields .field span.checkbox {
              width: 100%;
              display: inline-flex; }
            #bp-dialog-overlay #wp-notification-dialog .dialog-body .content .fields .field span.checkbox-text, wp-notification-dialog #wp-notification-dialog .dialog-body .content .fields .field span.checkbox-text, bp-dialog #wp-notification-dialog .dialog-body .content .fields .field span.checkbox-text {
              width: 100%; }
      #bp-dialog-overlay #wp-notification-dialog .dialog-body .actions, wp-notification-dialog #wp-notification-dialog .dialog-body .actions, bp-dialog #wp-notification-dialog .dialog-body .actions {
        background-color: white;
        display: flex;
        flex-direction: row-reverse;
        padding: 8px; }
        #bp-dialog-overlay #wp-notification-dialog .dialog-body .actions button, wp-notification-dialog #wp-notification-dialog .dialog-body .actions button, bp-dialog #wp-notification-dialog .dialog-body .actions button {
          width: 152px;
          height: 38px; }
      #bp-dialog-overlay #wp-notification-dialog .dialog-body .dialogButtons, wp-notification-dialog #wp-notification-dialog .dialog-body .dialogButtons, bp-dialog #wp-notification-dialog .dialog-body .dialogButtons {
        background-color: white;
        display: flex;
        flex-direction: row;
        padding: 8px; }
        #bp-dialog-overlay #wp-notification-dialog .dialog-body .dialogButtons button, wp-notification-dialog #wp-notification-dialog .dialog-body .dialogButtons button, bp-dialog #wp-notification-dialog .dialog-body .dialogButtons button {
          width: 152px;
          height: 38px; }
  #bp-dialog-overlay #bp-dialog, wp-notification-dialog #bp-dialog, bp-dialog #bp-dialog {
    display: flex;
    flex-direction: column;
    max-width: 640px;
    min-width: 320px;
    margin: auto;
    height: 100%; }
    #bp-dialog-overlay #bp-dialog .header, wp-notification-dialog #bp-dialog .header, bp-dialog #bp-dialog .header {
      flex-grow: 3; }
    #bp-dialog-overlay #bp-dialog .footer, wp-notification-dialog #bp-dialog .footer, bp-dialog #bp-dialog .footer {
      flex-grow: 5; }
    #bp-dialog-overlay #bp-dialog .dialog-body, wp-notification-dialog #bp-dialog .dialog-body, bp-dialog #bp-dialog .dialog-body {
      background-color: white;
      overflow: scroll; }
      #bp-dialog-overlay #bp-dialog .dialog-body .title, wp-notification-dialog #bp-dialog .dialog-body .title, bp-dialog #bp-dialog .dialog-body .title {
        color: white;
        fill: white;
        background-color: steelblue;
        font-weight: 700;
        min-width: 180px;
        font-size: 16px;
        padding: 8px; }
      #bp-dialog-overlay #bp-dialog .dialog-body .content, wp-notification-dialog #bp-dialog .dialog-body .content, bp-dialog #bp-dialog .dialog-body .content {
        padding: 8px; }
        #bp-dialog-overlay #bp-dialog .dialog-body .content .fields, wp-notification-dialog #bp-dialog .dialog-body .content .fields, bp-dialog #bp-dialog .dialog-body .content .fields {
          margin-top: 8px; }
          #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field, wp-notification-dialog #bp-dialog .dialog-body .content .fields .field, bp-dialog #bp-dialog .dialog-body .content .fields .field {
            margin-top: 8px;
            margin-bottom: 16px; }
            #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field input, #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field select, wp-notification-dialog #bp-dialog .dialog-body .content .fields .field input, wp-notification-dialog #bp-dialog .dialog-body .content .fields .field select, bp-dialog #bp-dialog .dialog-body .content .fields .field input, bp-dialog #bp-dialog .dialog-body .content .fields .field select {
              width: 98%;
              padding: 4px;
              border-radius: 4px;
              border: 1px solid #a8c4ff;
              margin-top: 4px; }
            #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field select, wp-notification-dialog #bp-dialog .dialog-body .content .fields .field select, bp-dialog #bp-dialog .dialog-body .content .fields .field select {
              padding: 6px; }
            #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field input[type='checkbox'], wp-notification-dialog #bp-dialog .dialog-body .content .fields .field input[type='checkbox'], bp-dialog #bp-dialog .dialog-body .content .fields .field input[type='checkbox'] {
              width: 60px; }
            #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field span.checkbox, wp-notification-dialog #bp-dialog .dialog-body .content .fields .field span.checkbox, bp-dialog #bp-dialog .dialog-body .content .fields .field span.checkbox {
              width: 100%;
              display: inline-flex; }
            #bp-dialog-overlay #bp-dialog .dialog-body .content .fields .field span.checkbox-text, wp-notification-dialog #bp-dialog .dialog-body .content .fields .field span.checkbox-text, bp-dialog #bp-dialog .dialog-body .content .fields .field span.checkbox-text {
              width: 100%; }
      #bp-dialog-overlay #bp-dialog .dialog-body .actions, wp-notification-dialog #bp-dialog .dialog-body .actions, bp-dialog #bp-dialog .dialog-body .actions {
        background-color: white;
        display: flex;
        flex-direction: row-reverse;
        padding: 8px; }
        #bp-dialog-overlay #bp-dialog .dialog-body .actions button, wp-notification-dialog #bp-dialog .dialog-body .actions button, bp-dialog #bp-dialog .dialog-body .actions button {
          width: 152px;
          height: 38px; }
      #bp-dialog-overlay #bp-dialog .dialog-body .dialogButtons, wp-notification-dialog #bp-dialog .dialog-body .dialogButtons, bp-dialog #bp-dialog .dialog-body .dialogButtons {
        background-color: white;
        display: flex;
        flex-direction: row;
        padding: 8px; }
        #bp-dialog-overlay #bp-dialog .dialog-body .dialogButtons button, wp-notification-dialog #bp-dialog .dialog-body .dialogButtons button, bp-dialog #bp-dialog .dialog-body .dialogButtons button {
          width: 152px;
          height: 38px; }

bp-link {
  text-decoration: none;
  margin-left: 18px; }
  bp-link .content {
    display: flex;
    flex-direction: row;
    align-items: center; }
    bp-link .content .link-label {
      font-size: 14px;
      color: steelblue; }
    bp-link .content.hazard {
      fill: red;
      color: red; }
      bp-link .content.hazard .link-label {
        color: darkred; }
      bp-link .content.hazard .icon svg {
        fill: darkred; }
    bp-link .content:hover {
      fill: red;
      color: red;
      text-decoration: underline;
      cursor: pointer; }
      bp-link .content:hover .link-label {
        color: red; }
      bp-link .content:hover .icon svg {
        fill: red; }
    bp-link .content .icon {
      padding-top: 6px; }
      bp-link .content .icon svg {
        fill: steelblue;
        width: 16px;
        height: 16px; }

bp-link-large {
  text-decoration: none;
  margin-left: 18px; }
  bp-link-large .content {
    display: flex;
    flex-direction: row;
    align-items: center; }
    bp-link-large .content .link-label {
      font-size: 24px;
      color: steelblue; }
    bp-link-large .content:hover {
      fill: blue;
      color: blue;
      text-decoration: underline;
      cursor: pointer; }
      bp-link-large .content:hover .link-label {
        color: blue; }
      bp-link-large .content:hover .icon-large svg {
        fill: blue; }
    bp-link-large .content .icon-large {
      padding-top: 6px; }
      bp-link-large .content .icon-large svg {
        fill: red;
        width: 80px;
        height: 80px; }

bp-img.header-logo svg {
  height: 50px;
  flex-grow: 0;
  margin-right: 50px; }

bp-img.action-icon svg {
  width: 32px;
  height: 32px; }

bp-img.cancel-icon svg {
  margin: 4px 4px 0 4px;
  width: 12px;
  height: 12px;
  fill: red; }

bp-img.verify-icon svg {
  margin: 4px 4px 0 4px;
  width: 12px;
  height: 12px;
  fill: green; }

bp-img.button-icon svg {
  margin: 4px 4px 0 4px; }

bp-img.status-indicator svg {
  height: 48px;
  width: 48px;
  flex-shrink: 0;
  padding: 4px; }

bp-img.progress0 svg .level1 {
  fill: red; }

bp-img.progress0 svg .level2 {
  fill: white; }

bp-img.progress0 svg .level3 {
  fill: white; }

bp-img.progress0 svg .level4 {
  fill: white; }

bp-img.progress0 svg .level5 {
  fill: white; }

bp-img.progress20 svg .level1 {
  fill: red; }

bp-img.progress20 svg .level2 {
  fill: orange; }

bp-img.progress20 svg .level3 {
  fill: white; }

bp-img.progress20 svg .level4 {
  fill: white; }

bp-img.progress20 svg .level5 {
  fill: white; }

bp-img.progress40 svg .level1 {
  fill: orange; }

bp-img.progress40 svg .level2 {
  fill: orange; }

bp-img.progress40 svg .level3 {
  fill: orange; }

bp-img.progress40 svg .level4 {
  fill: orange; }

bp-img.progress40 svg .level5 {
  fill: white; }

bp-img.progress80 svg .level1 {
  fill: orange; }

bp-img.progress80 svg .level2 {
  fill: green; }

bp-img.progress80 svg .level3 {
  fill: green; }

bp-img.progress80 svg .level4 {
  fill: green; }

bp-img.progress80 svg .level5 {
  fill: white; }

bp-img.progress100 svg .level1 {
  fill: green; }

bp-img.progress100 svg .level2 {
  fill: green; }

bp-img.progress100 svg .level3 {
  fill: green; }

bp-img.progress100 svg .level4 {
  fill: green; }

bp-img.progress100 svg .level5 {
  fill: green; }
